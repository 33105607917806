









































import Vue, {PropType} from "vue";
import {BankAccountData, PaymentCardData} from "@/types";
import { mapActions, mapGetters } from "vuex";
import BankAccountBlock from "@/components/payments/BankAccountBlock.vue";
import PaymentCardBlock from "@/components/payments/PaymentCardBlock.vue";

export default Vue.extend({
  name: "payment-methods-assignments-modal" as string,

  components: {
    "bank-account-block": BankAccountBlock,
    "payment-card-block": PaymentCardBlock
  },

  props: {
    cards: Array as PropType<Array<PaymentCardData>>,
    accounts: Array as PropType<Array<BankAccountData>>,
    data: Object as PropType<object>
  },

  data() {
    return {
      selectedPaymentMethod: '',
      selectedPaymentCard: null,
      selectedBankAccount: null,
    }
  },

  computed: {
    ...mapGetters('companyModule',{
      company: "GET_COMPANY"
    }),
    venueInfo(): string {
      return `${this.data.name}`;
    },
    isSelectedNewPaymentCard(): boolean {
      return this.data.cardId !== this.selectedPaymentCard;
    }
  },

  methods: {
    ...mapActions("paymentModule", {
      createNewAssignPaymentMethod: "CREATE_PAYMENT_METHOD_ASSIGNMENTS"
    }),
    ...mapActions("emailsModule", {
      paymentMethodChangedEmail: "SUBSCRIPTION_OR_PAYMENT_METHOD_CHANGED_EMAIL"
    }),
    async assignVenuePaymentMethod(): Promise<void> {
      let payload: object;
      if(this.selectedPaymentCard){
        payload = {
          venueId: this.data.venueId,
          cardId:  this.selectedPaymentCard
        }
      }
      if(this.selectedBankAccount) {
        payload = {
          venueId: this.data.venueId,
          bankAccountId: this.selectedBankAccount
        }
      }

      await this.createNewAssignPaymentMethod(payload);

      this.$bvModal.hide("edit-venue-payment");

      if (this.isSelectedNewPaymentCard) {
        const oldPaymentCard: PaymentCardData = this.cards.find(c => c.id === this.data.cardId);
        const newPaymentCard: PaymentCardData = this.cards.find(c => c.id === this.selectedPaymentCard);

        const payload: object = {
          changedEntity: "1",
          oldEntity: `${oldPaymentCard.issuerType} ********${oldPaymentCard.lastFourNumbers}`,
          newEntity: `${newPaymentCard.issuerType} ********${newPaymentCard.lastFourNumbers}`,
        }

        this.paymentMethodChangedEmail(payload);
      }
    }
  },

  watch: {
    data(value) {
      this.selectedPaymentCard = !!value.cardId ? value.cardId : null;
      this.selectedBankAccount = !!value.bankAccountId ? value.bankAccountId : null;
    }
  }
})
