
































import Vue, {PropType} from "vue";
import {PaymentCardData} from "@/types";

export default Vue.extend({
  name: "payment-card-block" as string,

  props: {
    data: Object as PropType<PaymentCardData>,
    selectedCard: Number as PropType<number>,
    selectable: Boolean as PropType<boolean>,
  },

  data() {
    return {
      selectedPaymentCard: this.selectedCard
    }
  },

  computed: {
    paymentCardLogo(): string {
      let logo: string = "";

      switch ((this.data.issuerType).toUpperCase()) {
        case "VISA":
          logo = "visa-logo.svg";
          break;
        case "MASTERCARD":
          logo = "mastercard-logo.svg";
          break;
        case "AMERICAN EXPRESS":
          logo = "american-express-logo.svg";
          break;
      }

      return require("@/assets/images/payment-cards-logos/" + logo);
    }
  },

  methods: {
    setSelectedPaymentCard(cardId: number): void {
      if (this.selectable) {
        this.selectedPaymentCard = cardId;
        this.$emit("change", { paymentId: cardId, paymentType: 1 })
      }
    }
  },

  watch: {
    selectedCard: {
      handler(value: number) {
        if (value === this.data.id) {
          this.selectedPaymentCard = value;
        } else {
          this.selectedPaymentCard = null;
        }
      }
    }
  }
})
