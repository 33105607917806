





















































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import SearchBar from "@/components/search-bar.vue";
import PaymentMethodAssignmentsModal from "@/components/modals/PaymentMethodAssignmentsModal.vue";
import BankAccountCard from "@/components/payments/BankAccountBlock.vue";
import PaymentCardCard from "@/components/payments/PaymentCardBlock.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import filterCircleIcon from "@iconify-icons/ion/filter-circle";
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  components: {
    Layout,
    SearchBar,
    "payment-method-assignments-modal": PaymentMethodAssignmentsModal,
    "bank-account-card" :BankAccountCard,
    "payment-card-card": PaymentCardCard,
    "custom-select": CustomSelect,
  },

  data() {
    return {
      venue: null,
      selectedVenueData: {},
      scrollBarOptions: {
        useBothWheelAxes: true,
        suppressScrollY: true,
        wheelSpeed: 0.5,
      },
      title: 'Payment Methods',
      icons: {
        home: filterCircleIcon,
      },
      currentPage: 1,
      perPage: 10,
      selectedVenues: [] as Array<number>
    }
  },

  async mounted(): Promise<void> {
    await this.fetchCompany(this.companyId);
    await this.setPaymentCards();
    await this.setBankAccounts();
    await this.setPaymentMethodAssignments();
  },

  computed: {
    ...mapGetters("paymentModule", {
      allPaymentMethods: "GET_PAYMENT_METHODS",
      paymentMethodsAssignments: "GET_PAYMENT_METHODS_ASSIGNMENTS",
      paymentCards: "GET_PAYMENT_CARDS",
      bankAccounts: "GET_BANK_ACCOUNTS"
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID",
      company: "GET_COMPANY"
    }),
    ...mapGetters("venueModule", {
      venues: "GET_VENUES"
    }),
    totalVenues(): number {
      return this.paymentMethodsAssignments.length;
    },
    venuesList(): Array<any> {
      return this.paymentMethodsAssignments.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    disableFilter(): boolean {
      return !this.selectedVenues.length;
    }
  },

  methods: {
    ...mapActions("paymentModule", {
      setPaymentCards: "FETCH_PAYMENT_CARDS",
      setBankAccounts: "FETCH_BANK_ACCOUNTS",
      setPaymentMethodAssignments: "SET_PAYMENT_METHOD_ASSIGNMENTS"
    }),
    ...mapActions("companyModule", {
      fetchCompany: "SET_COMPANY"
    }),
    goToManagePayments() {
      this.$router.push("/payment/manage");
    },
    emitVenuesImages(): void {
      this.currentPage = 1;
    },
    openEditModal(value: object): void {
      this.selectedVenueData = value;
    },
    setVenues(value: Array<number>): void {
      this.selectedVenues = value;
    },
    async filterPaymentMethodAssignments(): Promise<void> {
      let uniqueVenuesIdsString: string = "";
      this.selectedVenues.forEach((v: number) => { uniqueVenuesIdsString += `venueId%5B%5D=${v}&` })

      await this.setPaymentMethodAssignments(uniqueVenuesIdsString);
    },
  },

  watch: {
    companyId: {
      async handler(newValue: number, oldValue: number): Promise<void> {
        if (newValue != oldValue) {
          this.selectedVenues = [];
          await this.setPaymentCards();
          await this.setBankAccounts();
          await this.setPaymentMethodAssignments();
        }
      }
    }
  }
})
